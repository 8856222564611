<template>
  <div>
    <Toast
      v-if="toastData.show"
      :type="toastData.type"
      :message="toastData.message"
      :show="toastData.show"
      :time="10000"
      @clearToast="clearToast"
    />
    <div class="page-wrapper chiller-theme" style="margin-top: 81px">
      <main class="page-content">
        <div class="container-fluid p-0 m-0">
          <div>
            <div class="d-flex justify-content-between pb-5">
              <div>
                <ul
                  class="breadcrumb ProximaNovaRegular"
                  style="margin-top: 10px; margin-left: 10px"
                >
                  <li class="ProximaNovaBold">Cost Multiplier</li>
                </ul>
              </div>
            </div>
            <div class="row m-0 p-0 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="justify-content: center;">
              <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 mt-4">
                <div class="card">
                  <div
                    class="wrapper content-center px-3 pt-4 mb-4"
                    style="justify-content: space-between"
                  >
                    <h5 class="card-heading">Cost Multiplier</h5>
                  </div>
                  <div class="card-body">
                    <div class="row w-100">
                      <div
                        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 addList"
                      >
                        <div class="row">
                          <div
                            class="w-100 d-flex mb-5"
                            style="align-items: center;"
                          >
                            <WhiteDropdown
                              :options="orgnizationList"
                              :placeHolder="'Select'"
                              :labelText="'Orgnization'"
                              class=""
                              :selectedOption="selectedOrgnization"
                              @input="selectOrgnizationEvent($event)"
                            ></WhiteDropdown>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 addList"
                      >
                        <div class="row">
                          <div
                            class="w-100 d-flex mb-5"
                            style="align-items: center;"
                          >
                            <WhiteDropdown
                              :options="accountList"
                              :placeHolder="'Select'"
                              :labelText="'Accounts'"
                              class=""
                              :selectedOption="selectedAccount"
                              @input="selectAccountEvent($event)"
                            ></WhiteDropdown>

                            <div
                              class="col-lg-1 col-md-1 col-sm-1 col-xs-1 d-none"
                            >
                              <button
                                class="btn btnprimary"
                                @click="addList('accounts')"
                              >
                                <i class="fas fa-plus-square"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 addList"
                      >
                        <div class="row">
                          <div
                            class="w-100 d-flex mb-5"
                            style="align-items: center;"
                          >
                            <WhiteDropdown
                              :options="channelList"
                              :placeHolder="'Select'"
                              :labelText="'Channel'"
                              class=""
                              :selectedOption="selectedChannel"
                              @input="selectChannelEvent($event)"
                            ></WhiteDropdown>

                            <div
                              class="col-lg-1 col-md-1 col-sm-1 col-xs-1 d-none"
                            >
                              <button
                                class="btn btnprimary"
                                @click="addList('channel')"
                              >
                                <i class="fas fa-plus-square"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 addList"
                      >
                        <div class="row">
                          <div
                            class="w-100 d-flex mb-5"
                            style="align-items: center;"
                          >
                            <WhiteDropdown
                              :options="networkList"
                              :placeHolder="'Select'"
                              :labelText="'Network'"
                              class=""
                              :selectedOption="selectedNetwork"
                              @input="selectNetworkEvent($event)"
                            ></WhiteDropdown>

                            <div
                              class="col-lg-1 col-md-1 col-sm-1 col-xs-1 d-none"
                            >
                              <button
                                class="btn btnprimary"
                                @click="addList('network')"
                              >
                                <i class="fas fa-plus-square"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 addList"
                      >
                        <div class="row">
                          <div class="w-100 d-flex mb-5">
                            <div
                              class=" p-0 d-flex multidropdownLabel"
                              style="align-items: center;font-size: medium; width: 40%"
                            >
                              <span class="pl-3 w-100">Campaign</span>
                              <div class="line"></div>
                            </div>

                            <div
                              class="p-0 d-flex"
                              style="width:60%;align-items: center;"
                            >
                              <Treeselect
                                :rootOptions="campaignList"
                                :selectedValue="selectedCampaigns"
                                @selectedField="getSelectedCampaigns($event)"
                              />

                              <div
                                class="col-lg-1 col-md-1 col-sm-1 col-xs-1 d-none"
                              >
                                <button
                                  class="btn btnprimary"
                                  @click="addList('campaign')"
                                >
                                  <i class="fas fa-plus-square"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 addList"
                      >
                        <div class="row">
                          <div
                            class="w-100 d-flex"
                            style="align-items: center;"
                          >
                            <date-picker
                              v-model="customDate"
                              class="date-picker"
                              ref="datePicker"
                              format="MMM YYYY"
                              range
                              confirm
                              confirm-text="Done"
                            ></date-picker>
                            <WhiteDropdown
                              :options="filterDropdown"
                              :placeHolder="'Select'"
                              :selectedOption="selectedFilter"
                              :labelText="''"
                              @input="selectFilter($event)"
                              width="178px"
                            />
                          </div>
                          <div class="mb-5">
                            {{ startDate }} -
                            {{ endDate }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 addList"
                      >
                        <div class="row">
                          <div
                            class="w-100 d-flex mb-5"
                            style="align-items: center;"
                          >
                            <WhiteDropdown
                              :options="buyTypeList"
                              :placeHolder="'Select'"
                              :labelText="'Buy Type'"
                              class=""
                              :selectedOption="selectedBuyType"
                              @input="selectBuyTypeEvent($event)"
                            ></WhiteDropdown>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3addList"
                      >
                        <div class="row">
                          <InputBox
                            :labelId="'Metrics Value'"
                            :labelText="'Metrics Value'"
                            :type="'number'"
                            :userValue="metricsValue"
                            @getInputValue="getInputValue"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-footer w-100 d-flex"
                    style="justify-content: center;"
                  >
                    <w-button
                      :buttonLoader="buttonLoader"
                      :buttonText="'Save'"
                      @buttonClicked="saveCostMultiplier"
                      :float="'none'"
                      style="width: fit-content"
                      :class="{ resetPasswordLink: isDisable }"
                      :isDisable="isDisable"
                    />
                  </div>
                </div>
              </div>
              <div class="w-100 px-5 col-lg-11 col-md-11 col-sm-12 col-xs-12">
                <w-table
                  :tableProperties="tableDataValues"
                  :tableLoading="tableLoding"
                  :showHeading="true"
                  :headingText="'Existing Rules'"
                  :customRowColor="true"
                  :noDataText="'No existing rule found'"
                >
                </w-table>
                <Pagination
                  :currentPage="currentPage"
                  :totalRows="totalRows"
                  :perPage="rowPerPage"
                  @getSelectedPageEvent="getSelectedPageEvent"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { CostMultiplierServices } from "@/services/CostMultiplier.js";
import Treeselect from "@/widgets/MultiSelectDropdown.vue";
import WhiteDropdown from "@/widgets/Dropdown.vue";
const costMultiplierServices = new CostMultiplierServices();
import InputBox from "@/components/Profile/Input.vue";
import DatePicker from "vue2-datepicker";
import Button from "@/components/Profile/Button.vue";
import DataTable from "@/widgets/DataTable.vue";
import Pagination from "@/widgets/Pagination.vue";
import Toast from "@/components/Toast/Toast.vue";
export default {
  data() {
    return {
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      buttonLoader: "normal",
      selectedFilter: { text: "Date Range", id: "" },
      filterDropdown: [
        { text: "Yesterday", id: "yesterday" },
        { text: "Today", id: "today" },
        { text: "Last 7 Days", id: "Last 7 Days" },
        { text: "Last 30 Days", id: "Last 30 Days" },
        { text: "This Month", id: "This Month" },
        { text: "Last Month", id: "Last Month" },
        { text: "This Year", id: "This Year" },
        { text: "Last Year", id: "Last Year" },
        { text: "Custom", id: "customSelect" },
      ],
      metricsValue: "",
      tableDataValues: {
        fields: [
          {
            key: "accountName",
            label: "Account",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "fromDate",
            label: "From",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "toDate",
            label: "To",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "buyType",
            label: "Buy Type",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "channelName",
            label: "Channel",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "network",
            label: "Network Type",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },

          {
            key: "campaignNames",
            label: "Campaigns",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "metricsValue",
            label: "Value",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      tableLoding: false,
      networkList: [],
      rowPerPage: 10,
      currentPage: 1,
      totalRows: 0,
      selectedNetwork: { text: "--Select--", id: "" },
      isActive: false,
      padding: 0,
      buyTypeList: [
        { text: "CPV", id: "CPV" },
        { text: "CPA", id: "CPA" },
        { text: "CPC", id: "CPC" },
        { text: "CPM", id: "CPM" },
        { text: "CPE", id: "CPE" },
      ],
      menuMargin: 70,
      accountList: [],
      orgnizationList: [],
      selectedAccount: { text: "--Select--", id: "" },
      selectedOrgnization: { text: "--Select--", id: "" },
      campaignList: [],
      selectedCampaigns: [],
      channelList: [],
      selectedChannel: { text: "--Select--", id: "" },
      startDate: "",
      endDate: "",
      customDate: [],
      selectedBuyType: { text: "--Select--", id: "" },
      selectedPageNo: 0,
    };
  },
  methods: {
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    getInputValue(e) {
      this.metricsValue = e;
    },
    selectFilter(e) {
      if (this.selectedFilter === e) {
        return;
      }
      this.customDate = [];
      this.selectedFilter = e;
      var startDateLocal;
      var endDateLocal;
      if (e && e.id === "customSelect") {
        this.$refs.datePicker.openPopup();
      } else if (e && e.id === "today") {
        startDateLocal = new Date();
        endDateLocal = new Date();
      } else if (e && e.id === "yesterday") {
        let dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        startDateLocal = dateObj;
        endDateLocal = dateObj;
      } else if (e && e.id === "Last 7 Days") {
        startDateLocal = new Date(new Date().setDate(new Date().getDate() - 6));
        endDateLocal = new Date();
      } else if (e && e.id === "Last 30 Days") {
        startDateLocal = new Date(
          new Date().setDate(new Date().getDate() - 30)
        );
        endDateLocal = new Date();
      } else if (e && e.id === "This Month") {
        var today = new Date();
        startDateLocal = new Date(today.getFullYear(), today.getMonth() - 0, 1);
        endDateLocal = new Date();
      } else if (e && e.id === "Last Month") {
        today = new Date();
        startDateLocal = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDateLocal = new Date(today.getFullYear(), today.getMonth(), 0);
      } else if (e && e.id === "This Year") {
        today = new Date();
        startDateLocal = new Date(today.getFullYear(), 0, 1);
        endDateLocal = today;
      } else if (e && e.id === "Last Year") {
        today = new Date();
        startDateLocal = new Date(new Date().getFullYear(), -11, -30);
        endDateLocal = new Date(new Date().getFullYear(), 0, 0);
      }
      this.startDate = moment(startDateLocal).format("YYYY-MM-DD");
      this.endDate = moment(endDateLocal).format("YYYY-MM-DD");
    },
    // addList(type) {
    //   var campaign = prompt(
    //     "Please enter the values add to dropdown, For multipile values add comma in between",
    //     ""
    //   );
    //   var campaignList = campaign.split(",");
    //   for (var i = 0; i < campaignList.length; i++) {
    //     if (type === "network") {
    //       this.networkList.push({
    //         option: campaignList[i],
    //         value: campaignList[i],
    //       });
    //     } else if (type === "channel") {
    //       this.cha.push({
    //         option: campaignList[i],
    //         value: campaignList[i],
    //       });
    //     } else if (type === "accounts") {
    //       this.accountList.push({
    //         option: campaignList[i],
    //         value: campaignList[i],
    //       });
    //     } else if (type === "campaign") {
    //       this.campaignList.push({
    //         label: campaignList[i],
    //         id: campaignList[i],
    //       });
    //     }
    //   }
    //   console.log(type);
    // },
    getSelectedPageEvent(event) {
      if (this.selectedOrgnization.id === "") {
        return;
      }
      if (event != this.selectedPageNo) {
        this.selectedPageNo = event;
        this.currentPage = event;
        this.getTableData(JSON.parse(this.selectedOrgnization.id).value);
      }
    },
    getTableData(orgNumber) {
      costMultiplierServices
        .getTable(orgNumber, this.currentPage, this.rowPerPage)
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.content;
          this.totalRows = res.totalElements;
        });
    },
    selectNetworkEvent(e) {
      this.selectedNetwork = e;
      this.getCampaign();
    },
    selectBuyTypeEvent(e) {
      this.selectedBuyType = e;
    },
    selectChannelEvent(e) {
      this.selectedChannel = e;
      this.getAdType();
    },
    selectAccountEvent(e) {
      if (this.selectedOrgnization.id === "") {
        return;
      }
      this.selectedAccount = e;
      this.getChannelList(
        JSON.parse(this.selectedOrgnization.id).value,
        JSON.parse(this.selectedOrgnization.id).name
      );
    },
    selectOrgnizationEvent(e) {
      if (this.selectedOrgnization.id === "" && e.id === "") {
        return;
      }
      this.selectedOrgnization = e;
      this.getAccounts();
      this.getTableData(JSON.parse(this.selectedOrgnization.id).value);
    },
    getSelectedCampaigns(e) {
      this.selectedCampaigns = [];
      if (e.includes("all")) {
        this.selectedCampaigns = [];
        for (var i = 0; i < this.campaignList.length; i++) {
          this.selectedCampaigns.push(this.campaignList[i].id);
        }
      } else {
        this.selectedCampaigns = [];
        this.selectedCampaigns = e;
      }
    },
    getChannelList(orgNumber, orgName) {
      this.channelList = [];
      costMultiplierServices
        .getChannels(orgNumber, "channel", orgName)
        .then((res) => {
          var data = JSON.parse(res.response);
          for (var i = 0; i < data.length; i++) {
            this.channelList.push({
              text: data[i].channel,
              id: data[i].channel,
            });
          }
        });
    },
    getOrgnizations() {
      this.orgnizationList = [];
      costMultiplierServices.getOrgnizations("orgNumber").then((res) => {
        var data = JSON.parse(res.response);
        for (var i = 0; i < data.length; i++) {
          this.orgnizationList.push({
            text: data[i].orgName,
            id: JSON.stringify({
              name: data[i].orgName,
              value: data[i].orgNumber,
            }),
          });
        }
      });
    },
    getAdType() {
      if (this.selectedOrgnization.id === "") {
        return;
      }
      this.networkList = [];
      costMultiplierServices
        .getAdType(
          JSON.parse(this.selectedOrgnization.id).value,
          "adtype",
          JSON.parse(this.selectedOrgnization.id).name,
          this.selectedChannel.id,
          JSON.parse(this.selectedAccount.id).value
        )
        .then((res) => {
          var data = JSON.parse(res.response);
          for (var i = 0; i < data.length; i++) {
            this.networkList.push({
              text: data[i].network,
              id: data[i].network,
            });
          }
        });
    },
    getAccounts() {
      if (this.selectedOrgnization.id === "") {
        return;
      }
      this.accountList = [];
      costMultiplierServices
        .getAccounts(
          JSON.parse(this.selectedOrgnization.id).value,
          "account",
          JSON.parse(this.selectedOrgnization.id).name
        )
        .then((res) => {
          var data = JSON.parse(res.response);
          for (var i = 0; i < data.length; i++) {
            this.accountList.push({
              text: data[i].account,
              id: JSON.stringify({
                name: data[i].account,
                value: data[i].accountid,
              }),
            });
          }
        });
    },
    getCampaign() {
      if (this.selectedOrgnization.id === "") {
        return;
      }
      this.campaignList = [];
      this.selectedCampaigns = [];
      costMultiplierServices
        .getCampaign(
          JSON.parse(this.selectedOrgnization.id).value,
          "campaign",
          JSON.parse(this.selectedOrgnization.id).name,
          this.selectedChannel.id,
          JSON.parse(this.selectedAccount.id).value,
          this.selectedNetwork.id
        )
        .then((res) => {
          var data = JSON.parse(res.response);
          this.campaignList.push({ id: "all", label: "Select All" });
          for (var i = 0; i < data.length; i++) {
            this.campaignList.push({
              id: JSON.stringify({
                name: data[i].campaignname,
                value: data[i].campaignid,
              }),
              label: data[i].campaignname,
            });
          }
        });
    },
    saveCostMultiplier() {
      this.buttonLoader = "loader";
      var campName = [];
      var campId = [];
      for (var i = 0; i < this.selectedCampaigns.length; i++) {
        if (this.selectedCampaigns[i] !== "all") {
          campName.push(JSON.parse(this.selectedCampaigns[i]).name);
          campId.push(JSON.parse(this.selectedCampaigns[i]).value);
        }
      }
      var data = {
        orgNumber: JSON.parse(this.selectedOrgnization.id).value,
        orgName: JSON.parse(this.selectedOrgnization.id).name,
        accountId: JSON.parse(this.selectedAccount.id).value,
        accountName: JSON.parse(this.selectedAccount.id).name,
        campaignIds: campId,
        campaignNames: campName,
        fromDate: this.startDate,
        toDate: this.endDate,
        network: this.selectedNetwork.id,
        buyType: this.selectedBuyType.id,
        metricsValue: this.metricsValue,
        channel: this.selectedChannel.id,
      };
      costMultiplierServices
        .applyCostMultiplier(data)
        .then((res) => {
          console.log(res);
          this.buttonLoader = "normal";
        })
        .catch(() => {
          this.buttonLoader = "normal";
          this.toastData.show = true;
          this.toastData.type = "failed";
          this.toastData.message =
            "Something went wrong Please try after sometime.";
        });
    },
    sidebarToggle(e) {
      this.isActive = e;
      if (e == true) {
        this.padding = 63;
        this.menuMargin = 152;
      } else {
        this.padding = 0;
        this.menuMargin = 70;
      }
    },
  },
  created() {
    this.getOrgnizations();
  },
  computed: {
    isDisable() {
      if (
        this.selectedOrgnization.id !== "" &&
        this.selectedAccount.id !== "" &&
        this.selectedNetwork.id !== "" &&
        this.selectedChannel.id !== "" &&
        this.selectedBuyType.id !== "" &&
        this.metricsValue !== "" &&
        this.selectedCampaigns.length !== 0 &&
        this.startDate !== "" &&
        this.endDate !== ""
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    Toast,
    InputBox,
    Treeselect,
    DatePicker,
    WhiteDropdown,
    "w-button": Button,
    "w-table": DataTable,
    Pagination,
  },
  watch: {
    customDate(newVal) {
      if (newVal && newVal.length) {
        this.startDate = moment(newVal[0]).format("YYYY-MM-DD");
        this.endDate = moment(newVal[1]).format("YYYY-MM-DD");
        this.objectiveWiseData();
      }
    },
  },
};
</script>
<style>
.campaignlist .tree-label {
  display: none;
}
</style>

<style scoped>
.card-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.line {
  height: 50%;
  border-right: 2px solid #c6ccd6;
}
.multidropdownLabel {
  background: #eff5ff;
  color: #313846;
}

.addList .btnprimary {
  background-color: #d92e96;
}

.date-picker {
  width: 150px;
  position: absolute;
  right: 12px;
}
</style>
