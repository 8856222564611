import { ApiHelper } from "./helper/ApiHelper";

const apiHelper = new ApiHelper();
//const APP_SERVER_URL = process.env.VUE_APP_SERVER_URL;

export class CostMultiplierServices {
  constructor() {}

  getOrgnizations(requiredMetrics) {
    const uri = `health-monitor-module/cost-multiplier/get-orgNumber?requiredMetrics=${requiredMetrics}`;
    return apiHelper.get(uri);
  }
  getTable(orgNumber, page, size) {
    const uri = `health-monitor-module/cost-multiplier/init?orgNumber=${orgNumber}&page=${page}&size=${size}`;
    return apiHelper.get(uri);
  }
  getChannels(orgNumber, requiredMetrics, orgName) {
    const uri = `health-monitor-module/cost-multiplier/get-channel?orgNumber=${orgNumber}&requiredMetrics=${requiredMetrics}&orgName=${orgName}`;
    return apiHelper.get(uri);
  }
  getAdType(orgNumber, requiredMetrics, orgName, channel, accountId) {
    const uri = `health-monitor-module/cost-multiplier/get-adtype?orgNumber=${orgNumber}&requiredMetrics=${requiredMetrics}&orgName=${orgName}&channel=${channel}&accountId=${accountId}`;
    return apiHelper.get(uri);
  }
  getAccounts(orgNumber, requiredMetrics, orgName) {
    const uri = `health-monitor-module/cost-multiplier/get-account?orgNumber=${orgNumber}&requiredMetrics=${requiredMetrics}&orgName=${orgName}`;
    return apiHelper.get(uri);
  }
  getCampaign(
    orgNumber,
    requiredMetrics,
    orgName,
    channel,
    accountId,
    network
  ) {
    const uri = `health-monitor-module/cost-multiplier/get-campaign?orgNumber=${orgNumber}&requiredMetrics=${requiredMetrics}&orgName=${orgName}&channel=${channel}&accountId=${accountId}&network=${network}`;
    return apiHelper.get(uri);
  }
  applyCostMultiplier(data) {
    const uri = `health-monitor-module/cost-multiplier/set-cost-multilier`;
    return apiHelper.post(uri, data);
  }
}
